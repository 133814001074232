import React from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import backgroundSection from './../../assets/images/background/background-create.jpg';
import Container from '../../components/Container';
import { ScrollToElement } from '../../lib/utils';


const ContainerSection = styled(Box)(() => ({
  background: '#000 url('+backgroundSection+') center center no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover'
}));

const BoxSection = styled(Box)(() => ({
  width: 'calc(100% - 2rem)',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(1.5rem + 2vmin)',
  textAlign: 'center',
  padding: '2rem 1rem 2rem 1rem'
}));



const SectionCreate = () => {
  return (
    <ContainerSection id="SectionCreate">
      <BoxSection>
        <Container sx={{pt: 6, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <Typography variant="subtitle1" sx={{mb: 6}}>
            Create your own in-game marketplace & reach new players through our aggregator
          </Typography>
          <Box sx={{ my: 6 }}>
            <Button
              variant="contained"
              type="button"
              className="btn"
              onClick={() => { ScrollToElement('SectionContact') }}
              sx={{ display: 'inline-block' }}
            >
              Contact us
            </Button>
          </Box>
        </Container>
      </BoxSection>
    </ContainerSection>
  );
}

export default SectionCreate;