import React from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import backgroundSection from './../../assets/images/background/background-presentation.jpg';
import TitlePrimary from '../../components/TitlePrimary';
import IMG_Mask from './../../assets/images/illustration/hiroba-empty.png';
import IMG_Liquid01 from './../../assets/images/illustration/hiroba-liquid-01a.png';
import IMG_Liquid01Bis from './../../assets/images/illustration/hiroba-liquid-01b.png';
import IMG_Liquid02 from './../../assets/images/illustration/hiroba-liquid-02.png';
import IMG_Liquid03 from './../../assets/images/illustration/hiroba-liquid-03.png';
import IMG_Liquid04 from './../../assets/images/illustration/hiroba-liquid-04.png';
import IMG_Network01 from './../../assets/images/illustration/hiroba-network-01a.png';
import IMG_Network01Bis from './../../assets/images/illustration/hiroba-network-01b.png';
import IMG_Network02 from './../../assets/images/illustration/hiroba-network-02.png';
import IMG_Network03 from './../../assets/images/illustration/hiroba-network-03.png';
import IMG_Network04 from './../../assets/images/illustration/hiroba-network-04.png';
import IMG_Network05 from './../../assets/images/illustration/hiroba-network-05.png';
import IMG_Control01 from './../../assets/images/illustration/hiroba-control-01.png';
import IMG_Control02 from './../../assets/images/illustration/hiroba-control-02.png';
import IMG_Control03 from './../../assets/images/illustration/hiroba-control-03.png';
import IMG_Control04 from './../../assets/images/illustration/hiroba-control-04.png';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';

const ContainerSection = styled(Box)(() => ({
  background: '#000 url('+backgroundSection+') center center no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover'
}));

const BoxSection = styled(Box)(() => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(1.5rem + 2vmin)',
  textAlign: 'center',
  padding: '0rem 0rem 0rem 0rem'
}));

const SectionServices = () => {
  return (
    <ContainerSection id="SectionServices">
      <ParallaxProvider>
      <BoxSection>
          <Grid container spacing={0} alignItems="center">
            <Grid item md={6} xs={12}>
              <AnimationOnScroll animateIn="animate__slideInLeft" offset={300}>
                <Box sx={{p: 3, mt: {xs: 3, md: 0}}}>
                  <TitlePrimary 
                    textElement={'Liquid markets'}
                  />
                  <Typography variant="subtitle1" sx={{my: 6}}>
                    Hiroba's aggregator builds on top of in-game marketplaces for unified liquidity.
                  </Typography>
                </Box>
              </AnimationOnScroll>
            </Grid>
            <Grid item md={6} xs={12}>
              <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
                <ParallaxBanner
                  layers={[
                    {
                      image: IMG_Liquid01,
                      translateY: [0, 0],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Liquid01Bis,
                      translateY: [0, 50],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Liquid02,
                      translateY: [0, 0],
                      scale: [1, 1.3],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Liquid03,
                      translateY: [-5, 10],
                      translateX: [-10, 20],
                      scale: [0.8, 1.6],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Liquid04,
                      translateY: [0, 0],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    }
                  ]}
                >
                  <img src={IMG_Mask} alt="Hiroba" className="responsive" style={{opacity: '0'}} />
                </ParallaxBanner>
              </AnimationOnScroll>
            </Grid>
          </Grid>
          <Grid container spacing={0} alignItems="center" sx={{my:6}}>
            <Grid item md={6} xs={12} order={{ xs: 2, md: 1 }}>
              <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
                <ParallaxBanner
                  layers={[
                    {
                      image: IMG_Network01,
                      translateY: [0, 0],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Network01Bis,
                      translateY: [-10, 40],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Network02,
                      translateY: [0, 0],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Network03,
                      translateY: [0, 0],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Network04,
                      translateY: [20, 0],
                      scale: [0.6, 1],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Network05,
                      translateY: [0, 40],
                      scale: [0.6, 1.8],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    }
                  ]}
                >
                  <img src={IMG_Mask} alt="Hiroba" className="responsive" style={{opacity: '0'}} />
                </ParallaxBanner>
              </AnimationOnScroll>
            </Grid>
            <Grid item md={6} xs={12} order={{ xs: 1, md: 2 }}>
              <AnimationOnScroll animateIn="animate__slideInRight" offset={300}>
                <Box sx={{p: 3}}>
                  <TitlePrimary 
                    textElement={'Network effects'}
                  />
                  <Typography variant="subtitle1" sx={{my: 8}}>
                    Unified liquidity on the aggregator offers games a user acquisition channel, while in-game marketplaces directly benefit from the aggregator's adoption.
                  </Typography>
                </Box>
              </AnimationOnScroll>
            </Grid>
          </Grid>
          <Grid container spacing={0} alignItems="center">
            <Grid item md={6} xs={12}>
              <AnimationOnScroll animateIn="animate__slideInLeft" offset={300}>
                <Box sx={{p: 3}}>
                  <TitlePrimary 
                    textElement={'Keeping control'}
                  />
                  <Typography variant="subtitle1" sx={{my: 6}}>
                    Liquid, easily accessible in-game markets reduce the risk of 3rd-party marketplaces, which limit the game studios' control (e.g. royalties).
                  </Typography>
                </Box>
              </AnimationOnScroll>
            </Grid>
            <Grid item md={6} xs={12}>
              <AnimationOnScroll animateIn="animate__fadeIn" offset={300}>
                <ParallaxBanner
                  layers={[
                    {
                      image: IMG_Control01,
                      translateY: [0, 0],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Control02,
                      translateY: [0, 0],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Control03,
                      translateY: [0, 20],
                      scale: [1, 1.5],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    },
                    {
                      image: IMG_Control04,
                      translateY: [0, 0],
                      shouldAlwaysCompleteAnimation: true,
                      expanded: false,
                    }
                  ]}
                >
                  <img src={IMG_Mask} alt="Hiroba" className="responsive" style={{opacity: '0'}} />
                </ParallaxBanner>
              </AnimationOnScroll>
            </Grid>
          </Grid>
      </BoxSection>
      </ParallaxProvider>
    </ContainerSection>
  );
}

export default SectionServices;