import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, InputBase, Stack, SxProps, Typography, useTheme } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import TextGDPR from '../TextGDPR';
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";

type Props = {
  sx?: SxProps;
};



const CustomForm = ({ status, message, onValidated, sx }: any): JSX.Element => {
  const formRef = useRef<any>();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  /* *************************** */
  /* FORM CONTROL */
  const [data, setData] = useState({
    email: "",
    privacy: false
  });
  const [success_registered, setSuccessRegister] = useState(false);
  const { email, privacy } = data;
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setData({ ...data, [e.target.name]: e.target.value });
  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => setData({ ...data, [e.target.name]: e.target.checked ? e.target.value : false });
  const handleSubmit = async () => {
    setLoading(true);
    email &&
    onValidated({
        EMAIL: email
    });
  };

  useEffect(() => {
      if (status === "success") {
        setSuccessRegister(true);
        setLoading(false);
        initForm();
        setTimeout(function() {
          setSuccessRegister(false);
        }, 5000);
      } else if (status === "error") {
        setLoading(false);
      }
  }, [status]);
  
  const [form_submitted, setFormSubmit] = useState(false);
  const [form_start_submitted, setFormStartSubmit] = useState(false);
  const [error_email, setErrorEmail] = useState(true);
  const [error_privacy, setErrorPrivacy] = useState(true);
  const initForm = () => { 
    setData({
      email: "",
      privacy: false
    });
    setFormStartSubmit(false);
    initError();
  };
  const initError = () => { 
    setErrorEmail(false);
    setErrorPrivacy(false);
  };
  const validationForm = () => {
    setFormStartSubmit(true);
    setFormSubmit(true);
  };
  useEffect(() => {
    initError();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErrorEmail(false);
    } else {
      setErrorEmail(true);
    }
    if (privacy) {
      setErrorPrivacy(false);
    } else {
      setErrorPrivacy(true);
    }
    setFormSubmit(false);
    if (!error_email && !error_privacy) {
      handleSubmit();
    }
  }, [form_submitted]);

  /* *************************** */
  /* DIALOG GDPR */
  const [openGDPR, setOpenGDPR] = React.useState(false);
  const handleOpenGDPR = () => {
    setOpenGDPR(true);
  };
  const handleCloseGDPR = () => {
    setOpenGDPR(false);
  };
  const handleInvalidateGDPR = () => {
    setData({...data, privacy: false});
    setOpenGDPR(false);
  };
  const handleValidateGDPR = () => {
    setData({...data, privacy: true});
    setOpenGDPR(false);
  };

  return (
    <Box sx={[{mb: 3}, ...(Array.isArray(sx) ? sx : [sx])]}>
      <form ref={formRef} name="form-preregister">
        <Box sx={{mb: 3}}>
          <InputBase
            type="email"
            name="email"
            value={email}
            placeholder="Enter your Email"
            inputProps={{
              'aria-label': 'Enter your Email',
              autoComplete: "off",
            }}
            sx={{
              '& > input': { textAlign: 'center' },
              width: {xs: '80%', xl: '50%'},
              ...(form_start_submitted && error_email && {
                borderColor: (theme) => theme.palette.error.main
              })
            }}
            onChange={handleChange}
          />
        </Box>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{mb: 3}}>
          <Checkbox
            name="privacy"
            value="Accepted"
            sx={{ '& .MuiSvgIcon-root': { fontSize: '2rem' } }}
            onChange={handleChangeCheckbox}
            checked={privacy ? true : false}
          />
          <Typography 
            variant="body2"
            color={form_start_submitted && error_privacy?'error':'secondary'}
            textAlign="left"
            sx={{width: {xs: '80%', xl: '50%'}}}
          >
            I agree to the Privacy Policy and confirm that Hiroba can send me e-mails about my pre-registration, general product updates, and for other purposes.
            <Button variant="outlined" color="secondary" sx={{ml: 1}} onClick={handleOpenGDPR}>
              Read
            </Button>
            <Dialog
              open={openGDPR}
              onClose={handleCloseGDPR}
              fullWidth={true}
              maxWidth={'lg'}
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <TextGDPR />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleInvalidateGDPR}>Disagree</Button>
                <Button onClick={handleValidateGDPR} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </Typography>
        </Stack>
        <Typography 
          variant="body1"
          color={theme.palette.success.light}
          textAlign="center"
          sx={{
            mb: 3,
            ...(success_registered && { display: 'block'}),
            ...(!success_registered && { display: 'none'})
          }}
        >
          Thank your for pre-registering for Hiroba.<br />
          Check your inbox and confirm your pre-registration to get early access.
        </Typography>
        {!success_registered && (
          <Button
            variant="contained"
            type="button"
            className="btn"
            onClick={validationForm}
          >
            {loading ? <span className="element-flash">Registration...</span> : 'Get Early Access'}
          </Button>
        )}
        {status === "error" && (
          <Typography 
            variant="body1"
            color={theme.palette.error.light}
            textAlign="center"
            sx={{
              mt: 3
            }}
          >
            {message}
          </Typography>
        )}
      </form>
    </Box>
  );
}

const MailchimpForm = ({ sx }: Props) => {
    const url = `${process.env.REACT_APP_MAILCHIMP_URL}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    return (
      <>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData: EmailFormFields) => subscribe(formData)}
              sx={sx}
            />
          )}
        />
      </>

    )
}


export default MailchimpForm;