import React from 'react';
import { Box, Stack, styled, SxProps, Typography } from '@mui/material';
import IconQuoteLeft from '../../assets/images/style/quote-top-left.svg';
import IconQuoteRight from '../../assets/images/style/quote-bottom-right.svg';

type Props = {
  textElement: JSX.Element | string;
  sx?: SxProps;
};

const QuoteLeft = styled(Box)(() => ({
  background: 'transparent url('+IconQuoteLeft+') top center no-repeat',
  backgroundSize: 'contain',
  width: '40px'
}));

const QuoteRight = styled(Box)(() => ({
  background: 'transparent url('+IconQuoteRight+') bottom center no-repeat',
  backgroundSize: 'contain',
  width: '40px',
}));

const TitlePrimary = ({ textElement, sx }: Props) => {
  return (
    <Stack direction="row" justifyContent="center" sx={{maxWidth: '100%'}}>
      <QuoteLeft />
      <Typography component="div" variant="h1" sx={sx ? sx : {mx: 3, my: 0}}>
        {textElement}
      </Typography>
      <QuoteRight />
    </Stack>
  );
}

export default TitlePrimary;