import React from 'react';
import { Box, styled, SxProps, Typography } from '@mui/material';

type Props = {
  textElement: JSX.Element | string;
  sx?: SxProps;
};

const ContainerSection = styled(Typography)(({ theme }) => ({
  borderTop: '1px ' + theme.palette.divider + ' solid',
  display: 'inline-block',
  paddingTop: '0.5rem',
  paddingLeft: '3rem',
  paddingRight: '3rem',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '1rem',
    paddingRight: '1rem'
  }
}));

const TitleSecondary = ({ textElement, sx }: Props) => {
  return (
    <Box sx={[{mb: 3}, ...(Array.isArray(sx) ? sx : [sx])]}>
      <ContainerSection variant="h2" color="primary" textAlign="center">
        {textElement}
      </ContainerSection>
    </Box>
  );
}


export default TitleSecondary;