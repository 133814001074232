import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Stack, SxProps, TextField, Typography, styled, useTheme } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import TextGDPR from '../TextGDPR';

type Props = {
  sx?: SxProps;
};

const LineForm = styled(Box)(() => ({
  marginBottom: '1rem',
  textAlign: 'left'
}));

const CustomForm = ({ sx }: Props): JSX.Element => {
  const formRef = useRef<any>();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  /* *************************** */
  /* FORM CONTROL */
  const [data, setData] = useState({
    name: "",
    email: "",
    url_sn: "",
    company_name: "",
    body_message: "",
    privacy: false
  });
  const [success_sent, setSuccessSend] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const { name, email, url_sn, company_name, body_message, privacy } = data;
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setData({ ...data, [e.target.name]: e.target.value });
  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => setData({ ...data, [e.target.name]: e.target.checked ? e.target.value : false });
  const handleSubmit = () => {
    setLoading(true);
    emailjs.sendForm('service_nuxlhfk', 'template_d8n6yca', formRef.current, 'qo-rljVOYYBNvP0yg')
    .then((result) => {
        //console.log(result.text);
        setStatus('success'); 
    }, (error) => {
        //console.log(error.text);
        setMessage(error.text);
        setStatus('error');
    });
  };

  useEffect(() => {
      if (status === 'success') {
        setSuccessSend(true);
        initForm();
        setTimeout(function() {
          setLoading(false);
          setSuccessSend(false);
        }, 5000);
      } else if (status === 'error') {
        initForm();
        setLoading(false);
      }
  }, [status]);
  
  const [form_submitted, setFormSubmit] = useState(false);
  const [form_start_submitted, setFormStartSubmit] = useState(false);
  const [error_name, setErrorName] = useState(true);
  const [error_email_sn, setErrorEmailSn] = useState(true);
  const [error_body_message, setErrorMessage] = useState(true);
  const [error_privacy, setErrorPrivacy] = useState(true);
  const initForm = () => { 
    setData({
      name: "",
      email: "",
      url_sn: "",
      company_name: "",
      body_message: "",
      privacy: false
    });
    setFormStartSubmit(false);
    initError();
  };
  const initError = () => {
    setErrorName(true);
    setErrorEmailSn(true);
    setErrorMessage(true);
    setErrorPrivacy(true);
  };
  const validationForm = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    setFormStartSubmit(true);
    setFormSubmit(true);
  };
  useEffect(() => {
    initError();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) || url_sn !== '') {
      setErrorEmailSn(false);
    } else {
      setErrorEmailSn(true);
    }
    if (privacy) {
      setErrorPrivacy(false);
    } else {
      setErrorPrivacy(true);
    }
    if (name !== '') {
      setErrorName(false);
    } else {
      setErrorName(true);
    }
    if (body_message !== '') {
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
    setFormSubmit(false);
    if (!error_name && !error_email_sn && !error_body_message && !error_privacy) {
      handleSubmit();
    }
  }, [form_submitted]);

  /* *************************** */
  /* DIALOG GDPR */
  const [openGDPR, setOpenGDPR] = React.useState(false);
  const handleOpenGDPR = () => {
    setOpenGDPR(true);
  };
  const handleCloseGDPR = () => {
    setOpenGDPR(false);
  };
  const handleInvalidateGDPR = () => {
    setData({...data, privacy: false});
    setOpenGDPR(false);
  };
  const handleValidateGDPR = () => {
    setData({...data, privacy: true});
    setOpenGDPR(false);
  };

  return (
    <Box sx={[{mb: 3}, ...(Array.isArray(sx) ? sx : [sx])]}>
      <form ref={formRef} name="form-contact" onSubmit={validationForm}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <LineForm>
              <TextField
                type="text"
                name="name"
                value={name}
                placeholder="Name/pseudonym"
                autoComplete="new-name"
                inputProps={{
                  'aria-label': 'Name/pseudonym',
                  autoComplete: 'new-name'
                }}
                sx={{
                  width: {md: '90%', xs: '100%'},
                  ...(form_start_submitted && error_name && {
                    'input': {
                      borderColor: (theme) => theme.palette.error.main + ' !important'
                    }
                  })
                }}
                onChange={handleChange}
              />
            </LineForm>
            <LineForm>
              <TextField
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                autoComplete="new_email"
                inputProps={{
                  'aria-label': 'Email'
                }}
                sx={{
                  width: {md: '90%', xs: '100%'},
                  ...(form_start_submitted && error_email_sn && {
                    'input': {
                      borderColor: (theme) => theme.palette.error.main + ' !important'
                    }
                  })
                }}
                onChange={handleChange}
              />
            </LineForm>
            <LineForm>
              <TextField
                type="text"
                name="url_sn"
                value={url_sn}
                placeholder="Telegram/Twitter"
                autoComplete="new_url_sn"
                inputProps={{
                  'aria-label': 'Telegram/Twitter'
                }}
                sx={{
                  width: {md: '90%', xs: '100%'},
                  ...(form_start_submitted && error_email_sn && {
                    'input': {
                      borderColor: (theme) => theme.palette.error.main + ' !important'
                    }
                  })
                }}
                onChange={handleChange}
              />
            </LineForm>
            <LineForm>
              <TextField
                type="text"
                name="company_name"
                value={company_name}
                placeholder="Company name"
                autoComplete="new_company_name"
                inputProps={{
                  'aria-label': 'Company name'
                }}
                sx={{
                  width: {md: '90%', xs: '100%'},
                }}
                onChange={handleChange}
              />
            </LineForm>
          </Grid>
          <Grid item md={6} xs={12}>
            <LineForm>
              <TextField
                name="body_message"
                value={body_message}
                placeholder="Your message here"
                inputProps={{
                  'aria-label': 'Your message here',
                  autoComplete: "off",
                }}
                multiline
                rows={4}
                sx={{
                  width: '100%',
                  ...(form_start_submitted && error_body_message && {
                    'textarea': {
                      borderColor: (theme) => theme.palette.error.main + ' !important'
                    }
                  })
                }}
                onChange={handleChange}
              />
            </LineForm>
            <LineForm>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Checkbox
                  name="privacy"
                  value="Accepted"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: '2rem' } }}
                  onChange={handleChangeCheckbox}
                  checked={privacy ? true : false}
                />
                <Typography 
                  variant="body2"
                  color={form_start_submitted && error_privacy?'error':'secondary'}
                  textAlign="left"
                >
                  I agree to the Privacy Policy and confirm that Hiroba can send me e-mails about my request.
                  <Button variant="outlined" color="secondary" sx={{ml: 1}} onClick={handleOpenGDPR}>
                    Read
                  </Button>
                  <Dialog
                    open={openGDPR}
                    onClose={handleCloseGDPR}
                    fullWidth={true}
                    maxWidth={'lg'}
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <TextGDPR />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleInvalidateGDPR}>Disagree</Button>
                      <Button onClick={handleValidateGDPR} autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Typography>
              </Stack>
            </LineForm>
            <Typography 
              variant="body1"
              color={theme.palette.success.light}
              textAlign="center"
              sx={{
                mb: 3,
                ...(success_sent && { display: 'block'}),
                ...(!success_sent && { display: 'none'})
              }}
            >
              Thank your for your message.<br />
              We will respond as soon as possible.
            </Typography>
            {!success_sent && (
              <Button
                variant="contained"
                type="submit"
                className="btn"
              >
                {loading ? <span className="element-flash">Sending...</span> : 'Send'}
              </Button>
            )}
            {status === "error" && (
              <Typography 
                variant="body1"
                color={theme.palette.error.light}
                textAlign="center"
                sx={{
                  mt: 3
                }}
              >
                {message}
              </Typography>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}


export default CustomForm;