import createPalette from '@mui/material/styles/createPalette';
import createTheme from '@mui/material/styles/createTheme';
import createTypography from '@mui/material/styles/createTypography';

const theme = createTheme();
export const typography = createTypography(createPalette({}), {
  fontFamily: ['PrimeRegular'].join(','),
  fontSize: 14,
  h1: {
    fontSize: '3.5rem',
    fontWeight: 'normal',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem'
    },
    textTransform: 'uppercase'
  },
  h2: {
    fontSize: '2.2rem',
    lineHeight: 'inherit',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem'
    }
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem'
    }
  },
  h4: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem'
    }
  },
  button: {
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'normal'
  },
  subtitle1: {
    fontSize: '2.2rem',
    lineHeight: 'inherit',
    fontWeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem'
    }
  },
  subtitle2: {
    fontSize: '2rem',
    lineHeight: 'inherit',
    fontWeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.6rem'
    }
  },
  body1: {
    fontSize: '1.5rem',
    lineHeight: 'inherit',
    fontWeight: 'normal'
  },
  body2: {
    fontSize: '1.2rem',
    lineHeight: 'inherit',
    fontWeight: 'normal'
  },
  caption: {
    fontSize: '1rem',
    lineHeight: 'inherit',
    fontWeight: 'normal'
  }
});