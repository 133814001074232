import { createTheme } from '@mui/material';
import { typography } from './typography';

export const createMuiTheme = () => {
  return createTheme({
    palette: {
      primary: {
        main: '#ffff09'
      },
      secondary: {
        main: '#6c6c6b'
      },
      text: {
        primary: '#ffff09',
        secondary: '#6c6c6b'
      },
      divider: 'rgba(255,255,9,1)'
    },
    shape: {
      borderRadius: 10
    },
    typography,
    components: {
      MuiCssBaseline: {},
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#ffff09',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#6c6c6b',
            border: '1px solid #707070',
            borderRadius: 20,
            padding: '0.5rem 2rem 0.5rem 2rem'
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '.MuiInputBase-root': {
              border: 'none !important',
              padding: '0 0 0 0',
            },
            '.MuiInputBase-root input, .MuiInputBase-root textarea': {
              color: '#6c6c6b',
              border: '1px solid #707070',
              borderRadius: 20,
              padding: '1rem 2rem 1rem 2rem',
            },
            '.MuiInputBase-root fieldset': {
              border: 'none !important'
            }
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '.MuiDialogContentText-root': {
              color: 'white',
              maxHeight: 'calc(100vh - 10vh)'
            },
            '.MuiDialogContentText-root > p': {
              paddingBottom: '1rem'
            },
            '.MuiDialog-paper': {
              border: '1px solid rgba(255,255,9,0.3)'
            }
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            margin: '0 auto 0 auto',
            '@media (max-width: 899px)': {
              width: '90%'
            },
            '@media (min-width: 900px)': {
              width: '60%'
            }
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#000',
            boxSizing: 'border-box'
          }
        },
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  });
};