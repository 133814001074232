import React, { useEffect } from 'react';
import './App.css';
import { createMuiTheme } from './themes';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import "animate.css/animate.min.css";
import HeaderAppBar from './components/HeaderAppBar';
import SectionStart from './sections/Start/Index';
import SectionOverview from './sections/Overview/Index';
import SectionCreate from './sections/Create/Index';
import SectionSolution from './sections/Solution/Index';
import SectionServices from './sections/Services/Index';
import SectionPreregister from './sections/Preregister/Index';
import SectionContact from './sections/Contact/Index';
import BackTop from './components/BackTop';

function App() {
  const theme = createMuiTheme();
  const [active_backtop, setBackTop] = React.useState(false);

  useEffect(() => {
    window.onscroll = () =>
      (window.pageYOffset > 100) ? setBackTop(true) : setBackTop(false);
  });

  return (
    <MuiThemeProvider theme={theme}>
      <HeaderAppBar />
      <SectionStart />
      <SectionOverview />
      <SectionPreregister />
      <SectionCreate />
      <SectionSolution />
      <SectionServices />
      <SectionContact />
      { active_backtop && <BackTop /> }
    </MuiThemeProvider>
  );
}

export default App;
