import React from 'react';
import { Box, Button, Stack, styled } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import backgroundSection from './../../assets/images/background/background-space.jpg';
import TitlePrimary from '../../components/TitlePrimary';
import IMG_Hiroba from './../../assets/images/illustration/hiroba-gamer.png';
import { ScrollToElement } from '../../lib/utils';


const ContainerSection = styled(Box)(() => ({
  background: '#000 url('+backgroundSection+') center center no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover'
}));

const BoxSection = styled(Box)(() => ({
  width: 'calc(100% - 2rem)',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(1.5rem + 2vmin)',
  textAlign: 'center',
  padding: '0 1rem 0 1rem'
}));

const SectionStart = () => {
  return (
    <ContainerSection>
      <BoxSection>
        <TitlePrimary 
          textElement={
            <>
              THE <b>TOWN SQUARE</b> FOR GAMERS
              <Box sx={{my: 4}}>
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <AnimationOnScroll animateIn="animate__slideInLeft" animateOut="animate__fadeOutDown" initiallyVisible={true} offset={0}>
                    <Box sx={{mr: 2, textAlign: 'right'}}>
                      <Box>Trade<b>.</b></Box>
                      <Box>Discover<b>.</b></Box>
                      <Box>Connect<b>.</b></Box>
                    </Box>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__heartBeat" animateOut="animate__bounceOut" initiallyVisible={true} offset={0}>
                    <Box>
                      <img src={IMG_Hiroba} alt="Hiroba" width="400" className="responsive" />
                    </Box>
                  </AnimationOnScroll>
                </Stack>
              </Box>
              <Button 
                variant="contained"
                onClick={() => { ScrollToElement('SectionOverview') }}
                className="btn"
              >
                Continue
              </Button>
            </>
          }
          sx={{mx: { md: 15 }, my: { md: 10 }}}
        />
      </BoxSection>
    </ContainerSection>
  );
}

export default SectionStart;