import React from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton, styled } from '@mui/material';
import { ScrollToElement } from '../../lib/utils';

const ButtonBackTop = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: 10,
  transform: 'scale(1.25)',
  position: 'fixed',
  bottom: '1rem',
  right: '2rem',
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  }
}));

const goToTop = () => {
  ScrollToElement('SectionTop');
}

const BackTop = () => {
  return (
    <ButtonBackTop
      aria-label="Back to Top"
      edge="end"
      onClick={goToTop}
    >
      <ExpandLessIcon />
    </ButtonBackTop>
  );
}

export default BackTop;