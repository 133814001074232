import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Logo from './../../assets/images/logo.svg';
import { Container, Link, Stack, styled } from '@mui/material';
import { ScrollToElement } from '../../lib/utils';
import {ReactComponent as IconTwitter} from './../../assets/images/icon/twitter.svg';
import {ReactComponent as IconMessage} from './../../assets/images/icon/message.svg';


interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const sxContainer = {
  backgroundColor: 'rgba(0,0,0,0.6)',
  padding: '1rem 0 1rem 0',
}

const sxContainerMobile = {
  textAlign: 'center'
}

const sxLinkMobile = {
  display: 'block',
  padding: '1rem',
}

const NavDesktop = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center'
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'uppercase',
  fontFamily: 'PrimeRegular',
  textDecoration: 'none',
  fontSize: '1rem',
  transition: 'all 0.2s',
  cursor: 'pointer',
  margin: '0 1rem 0 1rem',
  '&:hover': {
    color: theme.palette.text.primary
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 1.5rem 0 1.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    margin: '0 3rem 0 3rem',
  }
}));

const ContainerIconsLinks = styled(Box)(() => ({
  position:'absolute',
  top: '0',
  right: '0',
}));

const LinkSocialNetwork = styled(Link)(() => ({
  '& > *': {
    fill: '#fff',
    height: '1.5rem'
  },
  '&:hover > *': {
    fill: '#ffff09'
  }
}));

export default function HeaderAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const goToSection = (elementId: string) => {
    ScrollToElement(elementId);
    if (mobileOpen) { handleDrawerToggle() }
  }

  const renderLogo = () => {
    return (
      <StyledLink onClick={() => { goToSection('SectionTop') }}>
        <img src={Logo} alt="Hiroba" />
      </StyledLink>
    );
  };

  const renderLinkScroll = (label: string, target: string, mobile?: boolean) => {
    return (
        <StyledLink
          onClick={() => { goToSection(target) }}
          sx={mobile ? sxLinkMobile : {}}
        >
          {label}
        </StyledLink>
    );
  };

  const drawer = (
    <Box sx={sxContainerMobile} onClick={handleDrawerToggle}>
      <Box sx={{ my: 2, position: 'relative' }}>
        {renderLogo()}
        <Box sx={{ position: 'absolute', top: '-0.5rem', right: '1rem' }}>
          <IconButton
            aria-label="Close Menu"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { md: 'none' }, color: '#fff' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box onClick={handleDrawerToggle}>
        {renderLinkScroll("Overview", "SectionOverview", true)}
        {renderLinkScroll("Join waitlist", "SectionPreregister", true)}
        {renderLinkScroll("Solution", "SectionSolution", true)}
        {renderLinkScroll("Services", "SectionServices", true)}
      </Box>
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{mt: '3rem'}}>
        <LinkSocialNetwork href="https://twitter.com/HirobaGG" target="_blank" title="Twitter">
          <IconTwitter />
        </LinkSocialNetwork>
        <LinkSocialNetwork href="#" onClick={() => { ScrollToElement('SectionContact') }} title="Contact Us">
          <IconMessage />
        </LinkSocialNetwork>
      </Stack>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box id="SectionTop" sx={{ display: 'flex' }}>
      <AppBar sx={sxContainer} component="nav" position="fixed">
        <Toolbar sx={{justifyContent: {xs: 'space-between', md: 'center'}}}>
          <Box sx={{ display: { md: 'none' } }}>
            {renderLogo()}
          </Box>
          <IconButton
            aria-label="Open Menu"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { md: 'none' }, color: '#fff' }}
          >
            <MenuIcon />
          </IconButton>
          <Container sx={{ display: { xs: 'none', md: 'block' } }}>
            <NavDesktop direction="row" justifyContent="center">
              {renderLinkScroll("Overview", "SectionOverview")}
              {renderLinkScroll("Join waitlist", "SectionPreregister")}
              {renderLogo()}
              {renderLinkScroll("Solution", "SectionSolution")}
              {renderLinkScroll("Services", "SectionServices")}
              <ContainerIconsLinks>
                <LinkSocialNetwork href="https://twitter.com/HirobaGG" target="_blank" title="Twitter">
                  <IconTwitter />
                </LinkSocialNetwork>
                <LinkSocialNetwork href="#" onClick={() => { ScrollToElement('SectionContact') }} title="Contact Us">
                  <IconMessage />
                </LinkSocialNetwork>
              </ContainerIconsLinks>
            </NavDesktop>
          </Container>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}