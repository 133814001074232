import React from 'react';
import { Box, Divider, Grid, styled, Typography } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import backgroundSection from './../../assets/images/background/background-presentation.jpg';
import Container from '../../components/Container';
import TitlePrimary from '../../components/TitlePrimary';
import TitleSecondary from '../../components/TitleSecondary';
import IMG_Schema from './../../assets/images/schema/platform-v3.svg';
import IMG_Game from './../../assets/images/screens/screen-game.png';
import IMG_Player from './../../assets/images/screens/screen-player.png';

const ContainerSection = styled(Box)(() => ({
  background: '#000 url('+backgroundSection+') center center no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover'
}));

const BoxSection = styled(Box)(() => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(1.5rem + 2vmin)',
  textAlign: 'center',
  padding: '6rem 1rem 2rem 1rem'
}));

const Separator = styled(Divider)(({ theme }) => ({
  margin: '3rem',
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block'
  }
}));

const SectionOverview = () => {
  return (
    <ContainerSection id="SectionOverview">
      <BoxSection>
        <Container>
          <Grid container spacing={6} alignItems="center">
            <Grid item md={6} xs={12} order={{ xs: 2, md: 1 }}>
              <AnimationOnScroll animateIn="animate__slideInLeft" offset={300}>
                <Box>
                  <img src={IMG_Schema} alt="Hiroba Platform" className="responsive" />
                </Box>
              </AnimationOnScroll>
            </Grid>
            <Grid item md={6} xs={12} order={{ xs: 1, md: 2 }}>
              <AnimationOnScroll animateIn="animate__slideInRight" offset={300}>
                <Box>
                  <TitlePrimary 
                    textElement={'Gaming NFT Aggregator'}
                  />
                  <Typography variant="subtitle1" sx={{my: 8}}>
                    Hiroba aggregates all your favorite gaming NFTs across in-game and external marketplaces in one place.
                  </Typography>
                  <TitleSecondary
                    textElement={'Built for gaming.'}
                    sx={{mt: 6}}
                  />
                </Box>
              </AnimationOnScroll>
            </Grid>
          </Grid>
          <Separator />
          <Grid container spacing={6} alignItems="center">
            <Grid item md={6} xs={12}>
              <AnimationOnScroll animateIn="animate__slideInLeft" offset={300}>
                <Box>
                  <TitlePrimary 
                    textElement={'Discovery Platform'}
                  />
                  <Typography variant="subtitle1" sx={{my: 6}}>
                    All NFTs, games, news, and events - in one place.
                  </Typography>
                </Box>
              </AnimationOnScroll>
            </Grid>
            <Grid item md={6} xs={12}>
              <AnimationOnScroll animateIn="animate__slideInRight" offset={300}>
                <Box>
                  <img src={IMG_Game} alt="Hiroba Platform" className="responsive" />
                </Box>
              </AnimationOnScroll>
            </Grid>
          </Grid>
          <Separator />
          <Grid container spacing={6} alignItems="center">
            <Grid item md={6} xs={12} order={{ xs: 2, md: 1 }}>
              <AnimationOnScroll animateIn="animate__slideInLeft" offset={300}>
                <Box>
                  <img src={IMG_Player} alt="Hiroba Platform" className="responsive" />
                </Box>
              </AnimationOnScroll>
            </Grid>
            <Grid item md={6} xs={12} order={{ xs: 1, md: 2 }}>
              <AnimationOnScroll animateIn="animate__slideInRight" offset={300}>
                <Box>
                  <TitlePrimary 
                    textElement={'Players\' Paradise'}
                  />
                  <Typography variant="subtitle1" sx={{my: 6}}>
                    Create your own player profile and level up by finishing meta quests. Show off your achievements with rare cosmetics you unlock for your NFT avatar along the way.
                  </Typography>
                </Box>
              </AnimationOnScroll>
            </Grid>
          </Grid>
        </Container>
      </BoxSection>
    </ContainerSection>
  );
}

export default SectionOverview;