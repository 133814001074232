import React from 'react';
import { Box, Container, Grid, Stack, styled, Typography } from '@mui/material';
import backgroundSection from './../../assets/images/background/background-solution.jpg';
import TitlePrimary from '../../components/TitlePrimary';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import ICON_DoubleQuote from './../../assets/images/icon/double-quote.svg';
import ICON_GameController from './../../assets/images/icon/game-controller.svg';
import ICON_Add from './../../assets/images/icon/add.svg';

const ContainerSection = styled(Box)(() => ({
  background: '#000 url('+backgroundSection+') center center no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover'
}));

const BoxSection = styled(Box)(() => ({
  width: 'calc(100% - 2rem)',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(1.5rem + 2vmin)',
  textAlign: 'center',
  padding: '2rem 1rem 2rem 1rem'
}));

const BoxIcon = styled(Stack)(() => ({
  height: '125px',
}));

const SectionSolution = () => {

  return (
    <ContainerSection id="SectionSolution">
      <BoxSection>
        <TitlePrimary 
          textElement={'Solution'}
        />
        <Container sx={{my:6}}>
          <Grid container columnSpacing={{ xs: 0, md: 3 }} sx={{mt: { xs: 0, md: 4 }}}>
            <Grid item xs={12} md={5}>
              <Box sx={{mb: {xs: 4, md: 0}}}>
                <AnimationOnScroll animateIn="animate__bounceIn" offset={300}>
                  <BoxIcon justifyContent="center" alignItems="center">
                    <img src={ICON_GameController} alt="Icon" className="responsive" />
                  </BoxIcon>
                  <Typography variant="h2" sx={{mb: 1, textTransform: 'initial'}}>
                    <b>White-label</b> solution for <b>in-hame marketplaces</b>
                  </Typography>
                  <Typography component="p" variant="body1" sx={{my: 3}}>
                    Convenient UX for players
                  </Typography>
                  <Typography component="p" variant="body1" sx={{my: 3}}>
                    Control for game studios & minimal development effort
                  </Typography>
                </AnimationOnScroll>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box sx={{mb: {xs: 4, md: 0}}}>
                <BoxIcon justifyContent="center" alignItems="center">
                  <img src={ICON_Add} alt="Icon" width="50" />
                </BoxIcon>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{mb: {xs: 4, md: 0}}}>
                <AnimationOnScroll animateIn="animate__bounceIn" offset={300}>
                  <BoxIcon justifyContent="center" alignItems="center">
                    <img src={ICON_DoubleQuote} alt="Icon" className="responsive" />
                  </BoxIcon>
                  <Typography variant="h2" sx={{mb: 1, textTransform: 'initial'}}>
                    Thematic marketplace <b>aggregator for gaming NFTs</b>
                  </Typography>
                  <Typography component="p" variant="body1" sx={{my: 3}}>
                    Discovery channel for players
                  </Typography>
                  <Typography component="p" variant="body1" sx={{my: 3}}>
                    User aquisition channel for game studios
                  </Typography>
                </AnimationOnScroll>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </BoxSection>
    </ContainerSection>
  );
}

export default SectionSolution;