import React from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import backgroundSection from './../../assets/images/background/background-contact.jpg';
import TitlePrimary from '../../components/TitlePrimary';
import Container from '../../components/Container';
import FormContact from './Form';


const ContainerSection = styled(Box)(() => ({
  background: '#000 url('+backgroundSection+') center center no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover'
}));

const BoxSection = styled(Box)(() => ({
  width: 'calc(100% - 2rem)',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(1.5rem + 2vmin)',
  textAlign: 'center',
  padding: '6rem 1rem 2rem 1rem'
}));

const SectionContact = () => {
  return (
    <ContainerSection id="SectionContact">
      <BoxSection>
        <TitlePrimary 
          textElement={'Contact'}
        />
        <Typography variant="subtitle1" sx={{my: 6}}>
          You have a game project or a question. Send us your message.
        </Typography>
        <Container sx={{pt: 6, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <Typography component="div" variant="subtitle1">
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOut="animate__fadeOutDown" initiallyVisible={false} offset={150}>
              <Stack direction="row" justifyContent="center">
                <FormContact sx={{width: {md: '80%', xs: '100%'}}}/>
              </Stack>
            </AnimationOnScroll>
          </Typography>
        </Container>
      </BoxSection>
    </ContainerSection>
  );
}

export default SectionContact;